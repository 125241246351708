import React from "react";
import { useTranslation } from "react-i18next";
import useService from "./useService";
import CustomHooks from "../../../@components/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useUserRole } from "../context";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import PublicIcon from "@mui/icons-material/Public";
import collectionIcon from "../../../static/collection-icon.png";
import collectionIconDark from "../../../static/collection-icon-dark.png";
import UI from "../../../@components/UI";
import PreviewSwiper from "./ListPreviewSwiper";

import useMediaQuery from "@mui/material/useMediaQuery";

const Item = ({ DAO, data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  console.log("data.files", data?.files);

  const getMaxTextLength = () => {
    if (data?.files?.length > 0) return 200;
    if (data?.content?.type === "poll") return 400;
    return 800;
  };

  return (
    <Paper
      sx={{
        backgroundColor: "transparent",
        width: "100%",
        height: "100%", // Ensure Paper can stretch
        position: "relative",
        display: "flex",
        flexDirection: "column",
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.2s ease-in-out",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.bgTransparent.primary,
            borderRadius: "10px 10px 0 0 ",
            display: "flex",
            p: { md: "20px 20px 16px", mobile: "20px 10px 16px" },
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                mr: { md: "14px", mobile: "10px" },
              }}
            >
              <Avatar src={DAO?.avatar_url} alt={"avatar"} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant={"h8"}>{DAO?.name}</Typography>
              <Typography
                variant={"subtitle4"}
                sx={{
                  display: { md: "flex", mobile: "flex" },
                }}
              >
                {moment(data.datetime_created).format("DD MMM YYYY HH:mm")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {data.is_public ? (
                <Tooltip
                  sx={{
                    width: "16px",
                    cursor: "pointer",
                    ml: "5px",
                    color: (theme) => theme.palette.text.primary,
                  }}
                  title={t("commons.public")}
                  placement="top"
                  arrow
                >
                  <PublicIcon
                    sx={{
                      color: (theme) => theme.palette.text.tertiary,
                      fontSize: "20px",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                  ></PublicIcon>
                </Tooltip>
              ) : (
                <Tooltip
                  sx={{
                    width: "16px",
                    cursor: "pointer",
                    ml: "5px",
                    color: (theme) => theme.palette.text.primary,
                  }}
                  title={`${t("brands.feed.tooltip")} ${data.collections.map(
                    (collection) => " " + collection.name
                  )}`}
                  placement="top"
                  arrow
                >
                  {theme.palette.type === "dark" ? (
                    <Box
                      component={"img"}
                      sx={{
                        width: "23.3px",
                        height: "17px",
                        marginRight: "7px",
                        opacity: "0.7",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          opacity: "1",
                        },
                      }}
                      src={collectionIcon}
                    />
                  ) : (
                    <Box
                      component={"img"}
                      sx={{
                        width: "23.3px",
                        height: "17px",
                        marginRight: "7px",
                        opacity: "0.7",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          opacity: "1",
                        },
                      }}
                      src={collectionIconDark}
                    />
                  )}
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            minHeight: "23px",
            px: { md: "20px", mobile: "10px" },
            pb: "10px",
            backgroundColor: (theme) => theme.palette.bgTransparent.primary,
            borderRadius: "0",
          }}
        >
          {data.files.length > 0 && (
            <UI.ReadMoreDescription
              maxTextLength={getMaxTextLength()}
              sx={{
                overflowWrap: "break-word",
              }}
            >
              {data.description}
            </UI.ReadMoreDescription>
          )}
        </Box>
        {data.files !== null && data.files.length > 0 && (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.bgTransparent.primary,
              borderRadius:"0 0 10px 10px",
              height: "340px"
            }}
          >
            <PreviewSwiper items={data.files} />
          </Box>
        )}
        {(data.files === null || data.files.length === 0) && (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.bgTransparent.primary,
              height: "auto",
              borderRadius:"0 0 10px 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              px: { md: "10px", mobile: "15px" },
              flexGrow: 1, // Ensure it grows to fill available space
            }}
          >
            <Typography sx={{ mb: "20px", fontSize:"12px" }}>
            {data.description}
            </Typography>
          </Box>
        )}
        {data.iframe_url !== null && (
          <Box
            sx={{
              position: "relative",
              background: "#000",
            }}
          >
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                src={data.iframe_url}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="PODKASST MRFI 1_1"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

const FeedSlider = ({ DAO }) => {
  const service = useService(DAO?.id);
  const { isBrandMember } = useUserRole();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("tablet"));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between("tablet", "md"));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.between("md", "lg"));

  const {
    data: items,
    isLoading,
    refetch,
  } = CustomHooks.useFetch(["posts"], service.list);
  return (
    <>
      {!isLoading && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={"No posts on Feed yet :("} />
        </Box>
      )}
      <Swiper
        grabCursor={true}
        slidesPerView={isMobile ? 1 : isTablet ? 2 : isMedium ? 2 : 3}
        spaceBetween={20}
        slidesPerGroup={1}
        autoplay={{
          delay: 6000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        pagination={true}
        modules={[Autoplay]}
        className="album-slider"
      >
        {items &&
          items.map((item, index) => (
            <SwiperSlide key={index}>
              <Item
                key={item.id}
                DAO={DAO}
                isBrandMember={isBrandMember}
                data={item}
                refetch={refetch}
                onPinPost={() => {}}
                onRemove={() => {}}
                sx={{ mx: "10px" }}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default FeedSlider;
