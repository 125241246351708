import Gateway from "../../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
   alert("bla");
  };

  return {
    save,
  };
};

export default useService;
