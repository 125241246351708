import Billing from "./billing";
import General from "./general";
import Governor from "./governor";
import Payment from "./payment";
import Plugin from "./plugin";
import Agent from "./agent";

const Settings = {
  Billing,
  General,
  Governor,
  Payment,
  Plugin,
  Agent,
};

export default Settings;
