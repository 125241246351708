import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomHooks from "../../../../@components/hooks";
import useService from "./useService";

const Index = ({ DAO }) => {
  const [telegramApiKey, setTelegramApiKey] = useState("");
  const [personality, setPersonality] = useState("");
  const [knowledge, setKnowledge] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const service = useService(DAO?.id);

  useEffect(() => {
    console.log("DAO:", DAO);
  }, [DAO]);

  const handleSwitchChange = (event) => {
    setIsEnabled(event.target.checked);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: { lg: "70px", md: "50px", tablet: "15px", mobile: "10px" },
          pb: "0px",
          pt: { lg: "30px", md: "10px", mobile: "0px" },
        }}
      >
        <Typography variant="h3">AI Agent</Typography>
      </Box>
      
      <Box sx={{ px: { lg: "70px", md: "50px", tablet: "15px", mobile: "10px" }, mt: 3 }}>
        <TextField
          label="Telegram Bot API Key"
          variant="outlined"
          fullWidth
          value={telegramApiKey}
          onChange={(e) => setTelegramApiKey(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Personality"
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          value={personality}
          onChange={(e) => setPersonality(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Knowledge"
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          value={knowledge}
          onChange={(e) => setKnowledge(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={isEnabled}
              onChange={handleSwitchChange}
              color="primary"
            />
          }
          label="Enable AI Agent"
        />
      </Box>
    </Box>
  );
};

export default Index;
