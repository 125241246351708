import React from "react";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Featured from "./featured";
import CollectionsSlider from "./collectionSlider";
import Nft101 from "./nft101";
import Promotion from "./promotion";
import promotionBanner from "../static/dancing.mp4";
import loyaltyBanner from "../static/nft-101-qvrseNft.png";
import aiBanner from "../static/nft-101-qvrseAi.png";
import metaverseBanner from "../static/nft-101-gamification.png";
import News from "./news";
import Trending from "./trending";
import UI from "../@components/UI";
import CustomHooks from "../@components/hooks";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import RedirectToProfileDialog from "./RedirectToProfileDialog";
import { useTranslation } from "react-i18next";



const PromotionItem = ({ text }) => {
  return (
    <Box component={"span"} sx={{ display: "flex" }}>
      <DoubleArrowIcon sx={{ mr: "8px", color: "#944dff", width: "18px" }} />
      {text}
    </Box>
  );
};

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let query = CustomHooks.useQuery();
  const location = useLocation();
  const [backgroundImage, setBackgroundImage] = React.useState("");

  const [emailRedirectDialogText, setEmailRedirectDialogText] =
    React.useState(null);

  const [emailRedirectToProfile, setEmailRedirectToProfile] =
    React.useState(null);

  React.useEffect(() => {
    const email_redirect = query.get("email_redirect");
    const email_redirect_profile = query.get("email_redirect_profile");
    if (email_redirect) setEmailRedirectDialogText(email_redirect);
    if (email_redirect_profile)
      setEmailRedirectToProfile(email_redirect_profile);
  }, [query]);

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        px: "0px",
      }}
    >
      <Box
        component={"img"}
        src={backgroundImage}
        sx={{
          position: "absolute",
          top: { md: "-100px", mobile: "110px" },
          maxHeight: "600px",
          minHeight: "400px",
          filter: "blur(100px)",
          width: "100%",
          opacity: { md: "0.5", mobile: "0.75" },
        }}
      />
      {emailRedirectDialogText && (
        <UI.InfoDialog
          contentText={emailRedirectDialogText}
          onClose={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
        />
      )}
      {emailRedirectToProfile && (
        <RedirectToProfileDialog
          contentText={emailRedirectToProfile}
          onClose={() => {
            setEmailRedirectToProfile(null);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setEmailRedirectToProfile(null);
            history.push(`/profile`);
          }}
        />
      )}
      <Box
        sx={{
          paddingLeft: { md: "80px", mobile: "0px" }, //SideMenu width
          paddingTop: { md: "10px", mobile: "10px" },
        }}
      >
      
     
      <Box
      sx={{
        position: "relative",
        mt: { md: "10px", mobile: "100px" },
        ml: { md: "20px", mobile: "10px" },
        mr: { md: "20px", mobile: "10px" },
        // Create a large container with a split background
        //background: { md: "linear-gradient(to right, #E8EAF661 50%, #E8EAF661 50%)", mobile: "linear-gradient(to bottom, #954dff 40%, #E8EAF6 50%)" },
        background:"transparent",
        borderRadius: "10px",
        overflow: "hidden",
        p: { md: "10px", mobile: "20px" },
      }}
    >
      <Grid container spacing={2} alignItems="center">
        {/* Left Side: Image or Illustration */}
        <Grid item xs={12} md={7}>
          <Box
            component="img"
            src="https://files.qvrse.io/first_img.png" // Replace with actual image path
            alt="Representative Visual"
            sx={{
              width: "100%",
              borderRadius: "10px",
              display: "block",
              objectFit: "cover",
            }}
          />
        </Grid>

        {/* Right Side: Heading, Text, and Button */}
        <Grid item xs={12} md={5}>
        <Box sx={{ pl: { md: 4, mobile: 0 }, pr: { md: 4, mobile: 0 }}}>
  <Typography
    variant="h2"
    sx={{
      fontSize: {
        md: "50px !important",
        mobile: "24px !important",
      },
     
      textAlign: { md: "left", mobile: "center" },
      fontWeight: 700,
      mb: 2,
    }}
  >
    Hey brands <br />
    we've got the <br />
    tools for you!
  </Typography>
  <Typography
    sx={{
      fontSize: { md: "20px", mobile: "18px" },
      textAlign: { md: "left", mobile: "center" },
      mb: 3,
    }}
  >
 Discover the tools for the Next - Gen branding and loyalty. 
  </Typography>
  <Box
    sx={{
      display: "flex",
      justifyContent: { md: "flex-start", mobile: "center" },
    }}
  >
    <Button
      variant="contained"
      color="primary"
      className="btn-blue"
      sx={{ fontWeight: 600, borderRadius: "8px" }}
      href="/brand/apply"
    >
      Create Your Profile 
    </Button>

  </Box>
</Box>

        </Grid>
      </Grid>
    </Box>

 

    <Box
  sx={{
    mt: { md: "150px", xs: "60px", mobile:"50px" },
    px: { md: "50px", xs: "20px", mobile:"10px" },
    py: { md: "50px", xs: "30px" },
    ml: { md: "20px", mobile: "10px" },
    mr: { md: "20px", mobile: "10px" },
    //backgroundColor: "#e8eaf6b3",
    borderRadius: "15px",
  }}
>
<Grid container spacing={4} alignItems="center">
  {/* Left Side Content */}
  <Grid item xs={12} md={4}>
    <Typography
      variant="h3"
      sx={{
        fontWeight: 700,
        fontSize: { md: "36px", xs: "24px" },
        mb: 2,
      }}
    >
      Unlock Your <br /> Brand’s Potential <br /> with Qvrse
    </Typography>

    <Typography
      variant="body2"
      sx={{
        
        fontSize: { md: "20px", xs: "18px" },
        mb: 3,
      }}
    >
      Powered by <strong>Qvrse</strong> - fueling growth, <br />engagement, and true brand connections.
    </Typography>

{/*  
    <Button variant="contained" color="primary" className="btn-blue" sx={{ fontWeight: 600 }} href="/brand/apply">
      Start Now 
    </Button>
    */}
  </Grid>

  {/* Right Side Cards */}
  <Grid item xs={12} md={8}
  sx={{
    overflowX: "auto",
        pb: 1,
        "&::-webkit-scrollbar": {
          height: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#cccccc61",
          borderRadius: "4px",
        },
  }}
  >
    <Box
      sx={{
        display: "flex",
        gap: { md: 6, mobile: 2 },
        
      }}
    >
      {/* Card 1: Loyalty (Blue conveys trust and reliability) */}
      <Card
        sx={{
          width: { md: "30%", mobile: "80%" },
          
          backgroundColor: "#954dffb3", // Deep Blue for Trust & Loyalty
          color: "#fff",
          borderRadius: "15px",
          flexShrink: 0,
          textAlign:"center"
        }}
        className="hover-shadow"
      >
        
        <CardMedia
          component="img"
          image={loyaltyBanner}
          alt="Loyalty"
          sx={{ borderRadius: "15px 15px 0 0", width: "100%" }}
        />
        <CardContent>
          <Typography variant="h1" sx={{ fontWeight: 700, mb: 1, color: "#fff", }}>
            Loyalty
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, fontSize:"18px" }}>
          Create a digital collector's album with NFTs, digital twins, and perks your community can trade and enjoy.
          </Typography>
        </CardContent>
      </Card>

      {/* Card 2: Engagement (Vibrant Orange/Yellow evokes energy and enthusiasm) */}
      <Card
        sx={{
          width: { md: "30%", mobile: "80%" },
          backgroundColor: "#FF9800b3", // Bright Orange for Energy & Engagement
          color: "#fff",
          borderRadius: "15px",
          flexShrink: 0,
          textAlign:"center"
        }}
        className="hover-shadow"
      >
     
        <CardMedia
          component="img"
          image={aiBanner}
          alt="Engagement"
          sx={{ borderRadius: "15px 15px 0 0", width: "100%" }}
        />
        <CardContent>
          <Typography variant="h1" sx={{ fontWeight: 700, mb: 1, color: "#fff", }}>
            Engagement
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, fontSize:"18px" }}>
          Use AI-powered agents to manage your community easily while staying true to your brand's values.

          </Typography>
        </CardContent>
      </Card>

      {/* Card 3: Interaction (Green suggests growth, vitality, and connection) */}
      <Card
        sx={{
          width: { md: "30%", mobile: "80%" },
          backgroundColor: "#2E7D32b3", // Vibrant Green for Growth & Interaction
          color: "#fff",
          borderRadius: "15px",
          flexShrink: 0,
          textAlign:"center"
        }}
        className="hover-shadow"
      >
        
        <CardMedia
          component="img"
          image={metaverseBanner}
          alt="Interaction"
          sx={{ borderRadius: "15px 15px 0 0", width: "100%" }}
        />
        <CardContent>
          <Typography variant="h1" sx={{ fontWeight: 700, mb: 1, color: "#fff", }}>
            Interaction
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, fontSize:"18px" }}>
          Go beyond social media — give your audience an exciting brand experience in your own metaverse.

          </Typography>
        </CardContent>
      </Card>
    </Box>
  </Grid>
</Grid>

</Box>


{/* 


<Box
            sx={{
              px: { lg: "20px", md: "20px", mobile: "10px" },
              mt: { md: "70px", mobile: "40px" },
            }}
          >

     
            <Box sx={{ mt: "20px" }}>
              <Promotion
                isExteranlUrl={false}
                reverse={true}
                image="https://files.qvrse.io/album-first.png"
                title='"EXPLORE QVRSE” ALBUM'
                subtitle="Discover the Qvrse platform through our digital album! Each section guides you through different features and perks. It’s a fun way to learn and explore — start now!"
                content={
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <PromotionItem
                      text={t("explore.promotion2.promotionItem1")}
                    />
                    <PromotionItem
                      text={t("explore.promotion2.promotionItem2")}
                    />
                    <PromotionItem
                      text={t("explore.promotion2.promotionItem3")}
                    />
                    <PromotionItem
                      text={t("explore.promotion2.promotionItem4")}
                    />
                  </Box>
                }
                url={"/brand/42/album/3"}
              />
            </Box>

          </Box>

*/}

<Box
  sx={{
    mt: { md: "70px", xs: "30px", mobile:"40px" },
    mb: { md: "20px", xs: "30px", mobile:"40px" },
    px: { md: "20px", xs: "20px", mobile: "0px" },
    py: { md: "20px", xs: "30px", mobile: "10px" },
    ml: { md: "0px", mobile: "10px" },
    mr: { md: "0px", mobile: "10px" },
    backgroundColor: "transparent",
    borderRadius: "15px",
  }}
  
>
<Grid container spacing={4} alignItems="center">

  {/* Left Side Content */}
  <Grid item xs={12} md={10}
  sx={{
    overflowX: "auto",
    pb: 1,
    maxWidth:"100%",
    "&::-webkit-scrollbar": {
      height: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#ccc",
      borderRadius: "4px",
    },
    order: { mobile: 2, md: 1 },
  }}
  >
    <Box
      sx={{
        display: "flex",
        gap: 2,
       
      }}
    >
      <Card
        sx={{
          width: { md: "24%", mobile: "70%" },
          //background: "linear-gradient(#ffffffb3, #ffffffb3)",
          background:"transparent",
    
          borderRadius: "10px",
          flexShrink: 0,
          border:"0px solid #954dff"
        }}
       
      >
        <CardMedia
          component="img"
          image="https://files.qvrse.io/purple-guardian.png"
          alt="Leader"
          sx={{ borderRadius: "15px 15px 0 0", width: "100%" }}
        />
        <CardContent
  
        >
          <Typography variant="h6" sx={{ fontWeight: 700, mb: 1 }}>
            Leader
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            The "Leader" agent leads the group, shares educational content, creates polls, and generates engaging images to guide and inspire your community.
          </Typography>
        
        </CardContent>
      </Card>

      <Card
        sx={{
          width: { md: "24%", mobile: "70%" },
          //background: "linear-gradient(#ffffffb3, #ffffffb3)",
          background:"transparent",
 
          borderRadius: "10px",
          flexShrink: 0,
          border:"0px solid #954dff"
        }}
       
      >
        <CardMedia
          component="img"
          image="https://files.qvrse.io/red-guardian.png"
          alt="Shiller"
          sx={{ borderRadius: "15px 15px 0 0", width: "100%" }}
        />
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: 700, mb: 1 }}>
            Shiller
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            The "Shiller" agent actively promotes your brand’s message, amplifying content and attracting new followers to boost engagement.
          </Typography>

        </CardContent>
      </Card>
      <Card
        sx={{
          width: { md: "24%", mobile: "70%" },
          //background: "linear-gradient(#ffffffb3, #ffffffb3)",
          background:"transparent",
       
          borderRadius: "10px",
          flexShrink: 0,
          border:"0px solid #954dff"
        }}
        
      >
        <CardMedia
          component="img"
          image="https://files.qvrse.io/green-guardian.png"
          alt="Support"
          sx={{ borderRadius: "15px 15px 0 0", width: "100%" }}
        />
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: 700, mb: 1 }}>
            Support
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            The "Support" agent provides reliable assistance, promptly addressing questions and concerns to ensure a positive user experience.
          </Typography>

        </CardContent>
      </Card>

      <Card
        sx={{
          width: { md: "24%", mobile: "70%" },
          //background: "linear-gradient(#ffffffb3, #ffffffb3)",
          background:"transparent",
        
          borderRadius: "10px",
          flexShrink: 0,
          border:"0px solid #954dff"
        }}
      
      >
        <CardMedia
          component="img"
          image="https://files.qvrse.io/blue-guardian.png"
          alt="Entertainer"
          sx={{ borderRadius: "15px 15px 0 0", width: "100%" }}
        />
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: 700, mb: 1 }}>
            Entertainer
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            The "Entertainer" agent engages your community with fun activities, lively discussions, and creative content to keep everyone entertained.
          </Typography>

        </CardContent>
      </Card>
    </Box>
  </Grid>

   {/* Right Side Content */}
   <Grid item xs={12} md={2}
   sx={{
    order: { mobile: 1, md: 2 }, // On mobile, this item comes second; on desktop, it goes first.
   
  }}
   >
    <Typography
      variant="h3"
      sx={{
        fontWeight: 700,
        fontSize: { md: "36px", xs: "24px" },
        pl: { md: "10px", mobile: "20px" },
        mb: 2,
      }}
    >
      Qvrse  AI Agents
    </Typography>

    <Typography
      variant="body2"
      sx={{
        pl: { md: "10px", mobile: "20px" },
        mb: 3,
      }}
    >
      They entertain and inform. 
      They are fast and efficient. 
    </Typography>

    <Button variant="contained" color="primary" sx={{ fontWeight: 600, ml: { md: "10px", mobile: "20px" }, }} 
    href="https://t.me/qvrse_ai_agent_bot"
    target="_blank"
    className="btn-blue">
      Say hello
    </Button>
  </Grid>
 
</Grid>

</Box>




<Box
  sx={{
    position: "relative",

    ml: { md: "30vh", mobile: "70vh" },
    maxWidth: "100%",
    background: `url('https://files.qvrse.io/metaverse-bg.gif') center center / cover no-repeat`,
    backgroundPosition: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    textAlign: "center",
    overflow: "hidden",
    borderRadius: "10px",
    ml: { md: "20px", mobile: "20px" },
    mr: { md: "20px", mobile: "20px" },
    mt: { md: "70px", mobile: "70px" },
    p: { md: "10px", mobile: "20px" },
  }}
>
  {/* Overlay for darker background if needed */}
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.4)", 
      backdropFilter:"blur(5px)",
      zIndex: 1,
    }}
  ></Box>

  {/* Content Container */}
  <Box sx={{ position: "relative", zIndex: 2, p: 2 }}>
    <Typography
      variant="h2"
      sx={{
        fontSize: { md: "50px !important", xs: "24px !important" },
        fontWeight: 700,
        mb: 2,
        color: "#f0f0f0",
      }}
    >
      Step Into Your <br />
      Brand’s Metaverse
    </Typography>
    <Typography
      sx={{
        fontSize: { md: "16px", xs: "14px" },
        mb: 4,
        maxWidth: "600px",
        mx: "auto",
        color: "#e0e0e0",
      }}
    >
      Transform your community into an immersive, interactive world. 
      Empower your brand with captivating virtual experiences that spark loyalty, 
      creativity, and growth. We have the expertise to bring your vision to life.
    </Typography>
    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        sx={{ fontWeight: 600, borderRadius: "8px" }}
        href="https://www.spatial.io/s/THE-QVRSE-STATION-6655c097311b7a21439823eb?share=3953462147396508479"
        target="_blank"
        className="btn-blue"
      >
        Visit Qvrse Station
      </Button>
      <Button
        variant="contained"
        color="primary"
        className="btn-blue"
        sx={{ fontWeight: 600, borderRadius: "8px" }}
        href="mailto:info@qvrse.io"
      >
        Contact Us
      </Button>
    </Box>
  </Box>
</Box>




<Box
            sx={{
              px: { lg: "20px", md: "50px", mobile: "10px" },
              mt: { md: "70px", mobile: "70px" },
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                mb: { md: "10px", mobile: "22px" },
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: {
                    md: "30px !important",
                    mobile: "24px !important",
                  },
                  textAlign: { md: "center", mobile: "center" },
                }}
              >
                {t("explore.trendingCollections.title")}
              </Typography>
            </Box>
            <CollectionsSlider
              onClick={(collection) => {
                history.push({
                  pathname: `/brand/${collection.dao.id}/collection/${collection.id}`,
                  state: { scrollToTop: true },
                });
              }}
            />
          </Box>

<Box
            sx={{
              px: { lg: "20px", md: "50px", mobile: "10px" },
              mt: { md: "70px", mobile: "70px" },
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                mb: { md: "10px", mobile: "22px" },
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: {
                    md: "30px !important",
                    mobile: "24px !important",
                  },
                  textAlign: { md: "center", mobile: "center" },
                }}
              >
                QVRSE 101
              </Typography>
            </Box>
            <Nft101 />
          </Box>






       
         

        



          <Box>

          <Box
            sx={{
              px: { lg: "20px", md: "50px", mobile: "10px" },
              mt: { md: "0px", mobile: "40px" },
            }}
          >
            <News />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Index;
