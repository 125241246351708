import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/Settings";
import MaterialMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "transparent !important",
  },
  root: {
    "&:hover": {
      backgroundColor: theme.palette.bgTransparent.primary,
    },
    "&:focus": {
      backgroundColor: theme.palette.bgTransparent.primary,
    },
  },
}));

const SettingsButton = ({ DAO }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const location = useLocation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title={""} arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            marginLeft: "15px",
            marginBottom: "3px",
            width: "32px",
            height: "32px",
            transition: "all 0.5s ease-in-out",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            transform: location.pathname.startsWith(`/profile/settings`)
              ? "scale(1.1) rotate(-90deg)"
              : "scale(1) rotate(0deg)",
            "&:hover": {
              transform: "scale(1.07) rotate(-90deg)",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            },
          }}
        >
          <MoreVertIcon
            sx={{ cursor: "pointer", fontSize: "20px", color: "#fff",}}
            onClick={handleClick}
          />
        </Box>
      </Tooltip>
      <MaterialMenu
        sx={{
          "& .MuiList-root.MuiMenu-list": {
            /* Add your custom CSS properties here */
            py: "20px",
            // ...
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            backgroundColor: (theme) => theme.palette.dialog.background,
            backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
          },
        }}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        elevation={0}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          autoFocus={false}
          selected={
            !!location.pathname.startsWith(`/brand/${DAO?.id}/settings/general`)
          }
          sx={{ p: "0px 40px 0px 40px" }}
          classes={{ selected: classes.selected, root: classes.root }}
          onClick={() => {
            history.push({
              pathname: `/brand/${DAO?.id}/settings/general`,
              state: { scrollToTop: false },
            });
            handleClose();
          }}
        >
          <Typography
            variant={"h8"}
            sx={{
              "&:hover": {
                color: (theme) => theme.palette.text.primary,
              },
              transition: "all 0.2s ease-in-out",
              color: (theme) =>
                !!location.pathname.startsWith(
                  `/brand/${DAO?.id}/settings/general`
                )
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            {t("brands.settingsMenu.general")}
          </Typography>
        </MenuItem>
        <MenuItem
          autoFocus={false}
          classes={{ selected: classes.selected, root: classes.root }}
          sx={{ p: "0px 40px 0px 40px" }}
          onClick={() => {
            history.push({
              pathname: `/brand/${DAO?.id}/settings/billing`,
              state: { scrollToTop: false },
            });
            handleClose();
          }}
        >
          <Typography
            variant={"h8"}
            sx={{
              "&:hover": {
                color: (theme) => theme.palette.text.primary,
              },
              transition: "all 0.2s ease-in-out",
              color: (theme) =>
                !!location.pathname.startsWith(
                  `/brand/${DAO?.id}/settings/billing`
                )
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            {t("brands.settingsMenu.billing")}
          </Typography>
        </MenuItem>
        <MenuItem
          autoFocus={false}
          classes={{ selected: classes.selected, root: classes.root }}
          sx={{ p: "0px 40px 0px 40px" }}
          onClick={() => {
            history.push({
              pathname: `/brand/${DAO?.id}/settings/payment`,
              state: { scrollToTop: false },
            });
            handleClose();
          }}
        >
          <Typography
            variant={"h8"}
            sx={{
              "&:hover": {
                color: (theme) => theme.palette.text.primary,
              },
              transition: "all 0.2s ease-in-out",
              color: (theme) =>
                !!location.pathname.startsWith(
                  `/brand/${DAO?.id}/settings/payment`
                )
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            {t("brands.settingsMenu.payment")}
          </Typography>
        </MenuItem>
        {/*<MenuItem*/}
        {/*  autoFocus={false}*/}
        {/*  classes={{ selected: classes.selected, root: classes.root }}*/}
        {/*  sx={{ p: "0px 40px 0px 40px" }}*/}
        {/*  onClick={() => {*/}
        {/*    history.push({*/}
        {/*      pathname: `/brand/${DAO?.id}/settings/governor`,*/}
        {/*      state: { scrollToTop: false },*/}
        {/*    });*/}
        {/*    handleClose();*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    variant={"h8"}*/}
        {/*    sx={{*/}
        {/*      "&:hover": {*/}
        {/*        color: (theme) => theme.palette.text.primary,*/}
        {/*      },*/}
        {/*      transition: "all 0.2s ease-in-out",*/}
        {/*      color: (theme) => !!location.pathname.startsWith(*/}
        {/*        `/brand/${DAO?.id}/settings/governor`*/}
        {/*      )*/}
        {/*        ? theme.palette.text.primary*/}
        {/*        : theme.palette.text.secondary,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {t("brands.settingsMenu.governor")}*/}
        {/*  </Typography>*/}
        {/*</MenuItem>*/}
        <MenuItem
          autoFocus={false}
          classes={{ selected: classes.selected, root: classes.root }}
          sx={{ p: "0px 40px 0px 40px" }}
          onClick={() => {
            history.push({
              pathname: `/brand/${DAO?.id}/settings/plugin`,
              state: { scrollToTop: false },
            });
            handleClose();
          }}
        >
          <Typography
            variant={"h8"}
            sx={{
              "&:hover": {
                color: (theme) => theme.palette.text.primary,
              },
              transition: "all 0.2s ease-in-out",
              color: (theme) =>
                !!location.pathname.startsWith(
                  `/brand/${DAO?.id}/settings/plugin`
                )
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            {t("brands.settingsMenu.plugin")}
          </Typography>
        </MenuItem>
        <MenuItem
          autoFocus={false}
          classes={{ selected: classes.selected, root: classes.root }}
          sx={{ p: "0px 40px 0px 40px" }}
          onClick={() => {
            history.push({
              pathname: `/brand/${DAO?.id}/settings/agent`,
              state: { scrollToTop: false },
            });
            handleClose();
          }}
        >
          <Typography
            variant={"h8"}
            sx={{
              "&:hover": {
                color: (theme) => theme.palette.text.primary,
              },
              transition: "all 0.2s ease-in-out",
              color: (theme) =>
                !!location.pathname.startsWith(
                  `/brand/${DAO?.id}/settings/agent`
                )
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            AI Agent
          </Typography>
        </MenuItem>
      </MaterialMenu>
    </>
  );
};

export default SettingsButton;
